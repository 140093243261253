<template>
  <Backpage title='terminal.activeDetail'>
    <template #header>
      <el-button type="danger" @click="handleDeleteError">{{ $t('terminal.clearError')}}</el-button>
    </template>
    <el-table
      :data="faliList"
      border
    >
      <el-table-column
        align="center"
        :label="$t('terminal.name')"
        prop="name"
        width="180"
      />
      <el-table-column
        align="center"
        label="LID"
        prop="lid"
        width="120"
      />
      <el-table-column
        align="center"
        :label="$t('terminal.serviveIP')"
        width="200"
        prop="ip"
      >
        <template slot-scope="scope">
          {{ scope.row.ip ? scope.row.ip : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('terminal.failTime')"
        width="300"
        prop="msg_time"
      >
        <template slot-scope="scope">
          {{ scope.row.msg_time | formatTime }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('terminal.errMsg')"
        prop="msg"
      />
    </el-table>
    <template #page>
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </Backpage>
</template>

<script>
import { terminal_msg_list, delete_terminal_msg_list } from '@/api/terminal.js'
import { formatTime as _formatTime } from '@/common/util.js'
import Backpage from '@/components/Backpage.vue'
export default {
  filters: {
    formatTime (value) {
      if (!value) {
        return ''
      }
      return _formatTime(value)
    }
  },
  components: {
    Backpage
  },
  data () {
    return {
      faliList: [],
      currentPage: 1,
      pageSize: 10,
      total: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    handleDeleteError() {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(async () => {
        const res = await delete_terminal_msg_list({ terminal_id: this.$route.params.terminal_id })
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.deleteSuccess'))
          this.terminalMsgList()
        }
      }).catch(() => {})
    },
    // 修改pageSize
    handleSizeChange (size) {
      this.pageSize = size
      this.terminalMsgList()
    },
    // 修改当前页码
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.terminalMsgList()
    },
    async terminalMsgList () {
      const param = {
        page_num: this.currentPage,
        page_size: this.pageSize,
        terminal_id: this.$route.params.terminal_id
      }
      const res = await terminal_msg_list(param)
      if (res.data.code === 0) {
        this.faliList = res.data.data.terminal_msg_list
        this.total = res.data.data.terminal_msg_count
      }
    },
    init () {
      this.terminalMsgList()
    }
  }
}
</script>
